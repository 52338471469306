.resume_container {
  padding: 20px;
  margin: 10px;
}
.header {
  text-align: center;
}
.profile_image {
  border-radius: 100%;
  margin: 10px;
}
.res_bio {
  color: grey;
  font-size: 16.5px;
  line-height: 25px;
}
.contact_info {
  display: flex;
  justify-content: space-around;
  justify-items: center;
  flex-wrap: wrap;
  margin: 10px;
}
p {
  padding-left: 10px;
}
.main_heads {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}
.about_me,
.education,
.experience,
.skills {
  margin: 10px;
  text-align: center;
}
.college {
  font-size: 20px;
}
.college_course {
  color: grey;
}
.edu_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.education_item {
  padding: 20px;
  background-color: #fdc095;
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0);
}
.education_item:hover {
  border: 2px solid #f4a166;
}
.skill_head {
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  text-decoration: underline;
  margin-top: 20px;
}
.sub_skills {
  text-align: center;
  text-decoration: underline;
  font-size: 16px;
  color: grey;
}
.skill_group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.skill_card {
  margin: 10px;
  padding: 10px;
  background-color: #fdc095;
  border-radius: 20px;
  color: rgba(0, 0, 0, 0.849);
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  gap: 10px;
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0);
}
.skill_card:hover {
  border: 2px solid #f4a166;
}
.certifications {
  margin-top: 20px;
}

.certifications h3 {
  margin-bottom: 10px;
  font-size: 1.1em;
  font-weight: bold;
}

.certification_item {
  margin-bottom: 15px;
}

.certification_item h4 {
  margin: 0;
  font-size: 1.1em;
}

.certification_item p {
  margin: 5px 0 0;
  font-size: 0.9em;
  color: #555;
}
.company_logo {
  height: 40px;
  width: 40px;
}
.main_cert_card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}
.cred {
  text-wrap: wrap;
}
.publication_sub {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.certi_view {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.certification_item,
.college_course,
.publish_item {
  background-color: #fdc095;
  padding: 20px;
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0);
}
.certification_item:hover {
  border: 2px solid #f4a166;
}
.publish_item:hover {
  border: 2px solid #f4a166;
}
.technical_skills,
.soft_skills {
  width: fit-content;
}
.skills {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.ex_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.ex_exp {
  background-color: #fdc095;
  padding: 20px;
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0);
  width: fit-content;
}
.Main_Hub_scocial_media {
  height: 60px;
  width: 60px;
}
