#main_about {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
#about {
  display: flex;
  justify-content: space-around;
  justify-items: center;
}
.about_me_cards {
  width: 25%;
}
.arsh_image {
  height: 300px;
  width: auto;
  object-fit: contain;
  box-shadow: 1px 1px 4px rgb(218, 208, 208);
  margin: auto;
}
.personal_info {
  list-style: none;
}
.table_cols {
  padding: 10px;
}
#edu_head {
  font-size: 20px;
  font-weight: bold;
}
.university {
  font-weight: 600;
  padding-left: 10px;
  list-style: none;
}
.uni_sub {
  padding-left: 20px;
}
.image_conatin {
  display: flex;
  justify-content: center;
  align-items: center;
}
#table-conatiner {
  display: flex;
  justify-content: center;
  align-items: center;
}
