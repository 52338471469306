* {
  font-family: "Rajdhani", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
#Home_Page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.arsh_info {
  /*border: 2px solid red;*/
  width: auto;
  margin: auto;
}
.arsh_profile {
  /*border: 2px solid red;*/
  width: auto;
  margin: auto;
}
.intro {
  font-size: 6vmax;
  margin: 2rem;
}
.arsh_name {
  font-weight: bold;
}
.Bio {
  color: rgb(41, 41, 41);
  font-size: 20px;
  margin: 2rem;
  text-wrap: wrap;
}
.hire_me_btn {
  text-decoration: none;
  color: white;
  background-color: var(--primary-background);
  padding: 12px;
  border-radius: 20px;
  font-size: 20px;
  margin: 2rem;
}
.hire_me_btn:hover {
  color: black;
  background-color: var(--secondary-text-background);
}
.Skills {
  display: grid;
  grid-template-columns: repeat(
    2,
    0.2fr
  ); /* Creates 3 columns of equal width */
  grid-template-rows: repeat(2, 80px); /* Creates 2 rows of 100px height */
  gap: 5px;
  margin-top: 50px;
  margin-left: 20px;
}
.main {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 90vh;
  width: 100%;
  margin: 20px;
}
.developer_image {
  width: 100%;
}
