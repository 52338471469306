.main_pro_card {
  padding: 20px;
}
.Title {
  font-size: 20px;
  font-weight: bold;
  padding-left: 10px;
}
.project_content {
  display: flex;
  justify-content: space-around;
  justify-items: center;
}
.image_container {
  height: 200px;
  width: 300px;
  overflow: hidden; /* Hide any overflow */
  display: flex; /* Center the image */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.project_image {
  height: 100%;
  width: 100%;
  object-fit: cover; /* Cover the container without stretching */
  object-position: center; /* Center the image */
  transition: transform 0.3s ease; /* Smooth zoom effect */
}

/* Optional: Zoom effect on hover */
.image_container:hover .project_image {
  transform: scale(1.2); /* Scale the image */
}

.outcome_container {
  padding: 10px;
}
.outcomne_head {
  font-size: 20px;
  font-weight: bold;
}
.outcome_bio {
  font-size: 16px;
}
.code_viewer {
  background-color: black;
  margin: 10px;
  padding: 10px;
}
.main_code {
  color: white;
  font-weight: bold;
  font-family: monospace;
}
.product_info_code {
  color: #0f0;
  padding: 20px;
  font-family: monospace;
  text-wrap: wrap;
  font-size: 12px;
  text-align: left;
}
.project_footer {
  margin: 10px;
  display: flex;
  justify-content: space-around;
  justify-items: center;
}
.github_link {
  text-decoration: none;
}
.github_logo {
  height: 40px;
  width: 40px;
}
.live_preview_project {
  background-color: var(--primary-background);
  text-decoration: none;
  font-size: 20px;
  padding: 10px;
  color: white;
  border-radius: 20px;
}
.github_link_cli {
  text-decoration: none;
  color: red;
}
.github_link_cli:active {
  color: yellow;
}
