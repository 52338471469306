#main_contact {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
  height: 80vh;
}
.form_input_main {
  width: auto;
  margin: 10px;
}
.inputGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: max-content;
  gap: 10px;
  margin: 6px;
  padding: 10px;
}

.user_input_icon {
  height: 40px;
  width: 40px;
  color: rgb(56, 56, 56);
}
.inputField_info {
  text-align: left;
}
.user_title_help {
  color: grey;
}
.main_input {
  height: 50px;
  font-size: 20px;
  width: auto;
  outline: none;
  padding-left: 10px;
  border: none;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0);
}
.send_btn {
  height: 40px;
  font-size: 20px;
  width: 100%;
  text-align: center;
  background-color: var(--primary-background);
  color: white;
  font-weight: bold;
  border: none;
}
.send_btn:hover {
  background-color: var(--secondary-text-background);
  color: black;
}
.submitgroup {
  padding: 10px;
  width: auto;
}
.issue {
  width: 92%;
  padding: 10px;
  font-size: 16px;
  height: 200px;
}
