.Navbar_main {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 12px;
  padding-bottom: 0;
}
.Nav {
  display: flex;
}
.nav_item {
  margin: 2px;
  padding: 8px;
  text-decoration: none;
  color: grey;
}
.nav_item:hover {
  background-color: grey;
  border-radius: 20px;
  color: white;
}

.logo {
  height: 60px;
  width: 60px;
}
.logo_img {
  border-radius: 100%;
}
#contact_btn {
  background-color: rgb(188, 188, 188);
  color: black;
  border-radius: 20px;
}
#contact_btn:hover {
  background-color: black;
  color: white;
}
.Menu_icons {
  height: 40px;
  width: 40px;
}
/*responsive*/
/*#FFD7C4 #FF9874 */
.mobile_nav {
  margin: 20px;
  background-color: #ff9974b7;
  border-radius: 20px;
  position: absolute;
  z-index: 10;
  width: 90vw;
}
.MobileNav {
  width: auto;
  text-align: center;
  padding: 20px;
  line-height: 60px;
}
.mnav_item {
  text-decoration: none;
  color: white;
  background-color: black;
  padding: 10px;
  border-radius: 20px;
}
