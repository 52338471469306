#chat {
  height: 100vh;
  margin: 40px;
  padding: 10px;
  text-wrap: wrap;
}

#terminal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: monospace;
}

#chat_terminal {
  font-family: monospace;
  background-color: #000;
  color: #0f0;
  padding: 20px;
  border: 1px solid #0f0;
  height: 60vh;
  overflow-y: auto;
  width: 100%;
  max-width: 800px;
  text-wrap: wrap;
}

#messages {
  margin-bottom: 10px;
}

.chat-message {
  white-space: pre-wrap;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

#main_terminal_head {
  color: #0f0;
  margin-right: 10px;
}

#chat_input {
  flex: 1;
  background: transparent;
  color: #0f0;
  border: none;
  outline: none;
  padding: 5px;
  font-family: monospace;
  font-size: 16px; /* Adjust as needed */
  position: relative;
}

#chat_input::after {
  content: "_";
  color: #0f0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 0.8em;
  line-height: 1;
  visibility: hidden;
}

#chat_input:focus::after {
  visibility: visible;
}

.command_head {
  color: #0f0;
  font-weight: bold;
}

.Heading_chat {
  text-align: center;
  color: red;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.command_panel {
  background-color: black;
  padding: 10px;
  border-radius: 10px;
  color: white;
  max-width: 800px;
  margin: 20px auto;
}
