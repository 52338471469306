:root {
  /* Backgrounds */
  --primary-background: #fe5b02;
  --secondary-background: #241f21;
  --tertiary-background: #a8a8a8;
  /* Text bg colors */
  --secondary-text-background: #ff8e3d;
  --tertiary-text-background: #e1e1e1;
  /* Text colors */
  --primary-text-color: #241f21;
  --secondary-text-color: #c5c5c5;
}

* {
  margin: 0;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
